.form-select {
	outline: none;
	background: none;
	border: none;
	color: #adb5bd !important;
	font-size: 14px;
	align-content: center;
	align-items: center;
	margin: 13px auto;
}

.stretch,
.select {
	flex: 1;
}

input.stretch.form-control::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

.loader {
	height: 50px;
	text-align: center;
	margin: 115px 0px;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 10px auto;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff black #fff black;
	animation: lds-dual-ring 1.2s linear infinite;

}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}